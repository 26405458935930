@import "src/styles/lib";

.Button {
  display: inline-flex;
  align-items: center;
  padding: 0.6em 0.9em;
  // border: 1px solid $accent-4;
  background: $a1;
  color: white;
  font-size: 0.9em;
  // font-weight: 900;
  // text-transform: uppercase;
  line-height: 1em;
  outline: none;
  cursor: pointer;
  border-radius: 2px;
  transition: background $transition-fast;
  appearance: none;
  border: none;

  &:focus,
  &:active,
  &:hover {
    background: $a2;
    color: $bgc;
  }
  & + .Button {
    margin-left: 0.3em;
  }

  &--selected {
    background: $accent-4;
    color: $bgc;
  }

  &--style {
    &-secondary {
      background: #838399;

      &:focus,
      &:active,
      &:hover {
        background: $tc;
      }
    }
  }

  &:disabled {
      background: $grey3;
  }
}
