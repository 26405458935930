@import "src/styles/lib";

a,
.Link {
  color: $a1;

  &:visited {
    color: $a4;
  }

  &--type {
    &-button {
        background: $accent-2;
        // background: $a1;
      // padding: 0.5em 1em;
    }
  }
}