@import "src/styles/lib";

.Blog {
    padding: 4em 4em;

    h1 {
    }

    b {
        font-weight: 800;
    }

    em {
        font-style: italic;
        font-weight: 700;
    }

    &__post {
        padding: 3em 0;

        img {
            max-width: 30em;
        }

        &__description {
            max-width: 45em;
        }

        p {
            margin: 1em 0;
            padding: 0;
        }
    }
}
