/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
@import url("https://rsms.me/inter/inter.css");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, .btn, .pill, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center; }

.p-2 {
  padding: 0.5em; }

.mb-2 {
  margin-bottom: 0.5em; }

html {
  font-family: 'Inter', sans-serif; }

*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

body {
  background: #f5f7f9;
  color: #292e31;
  font-size: 18px;
  line-height: 1.8em;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.014em;
  font-feature-settings: "ss03", "zero", "cv08", "cv11" 1;
  color: #292e31;
  background: #f8f9fa;
  text-transform: none; }
  body ::selection {
    background: #114D4D;
    color: white; }

h1 {
  font-size: 6vw;
  line-height: 1.1em;
  line-height: 0.9em;
  margin: 0.2em 0 0.6em;
  font-weight: 900; }

h2 {
  font-size: 4em;
  line-height: 1.1em;
  margin: 1em 0 0;
  font-weight: 800;
  letter-spacing: -0.022em;
  font-weight: 900; }
  @media (max-width: 900px) {
    h2 {
      font-size: 3em; } }

h3 {
  font-size: 3em;
  line-height: 1.1em;
  margin-bottom: 0.6em;
  font-weight: 800;
  letter-spacing: -0.022em; }

h5 {
  font-size: 1.3em;
  line-height: 1.1em;
  margin-bottom: 0.9em;
  margin-top: 0.6em;
  font-weight: 800;
  letter-spacing: -0.022em; }

h6, .btn, .pill {
  font-size: 0.9em;
  line-height: 1.1em;
  margin: 0.5em 0;
  margin-bottom: 0.6em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 400;
  color: #bdbdcf; }
  h6 b, .btn b, .pill b {
    font-weight: 900; }

a {
  color: #6E9987;
  text-decoration: none;
  transition: all 0.2s ease-out; }
  a:hover {
    color: #114D4D; }
  a::selection {
    background: #fff; }

p {
  padding-bottom: 1em; }

.tnum {
  font-feature-settings: 'tnum' 1; }

.btn {
  color: #6E9987;
  padding: 0.9em 1.4em;
  background: #114D4D;
  border: 1px solid;
  letter-spacing: 0.06em;
  font-weight: 500;
  transition: all 0.2s ease-out;
  color: white;
  cursor: pointer; }
  .btn:visited {
    color: #fff;
    background: #6E9987; }
  .btnfocus, .btn:hover {
    -webkit-text-fill-color: #fff;
    color: #fff;
    background: #292e31;
    text-decoration: none; }

.pill {
  background: #114D4D;
  color: #fff;
  margin-bottom: 0.6em;
  padding: 0.6em 0.9em;
  display: inline-block;
  font-size: 12px;
  line-height: 1em;
  border-radius: 2px; }
  .pill + .pill {
    margin-left: 0.6em; }

input, textarea, select, button {
  font-family: 'Inter', sans-serif;
  font-feature-settings: "zero", "frac", "case", "ss01" 1; }

li {
  padding: 0.4em 0.6em; }

p {
  margin: 1.2em 0; }

b {
  font-weight: 800; }

:focus {
  outline: #9980FA auto 5px; }

@media (max-width: 1200px) {
  .desktop {
    display: none; } }

@media (min-width: 1200px) {
  .mobile {
    display: none; } }

.App ::selection {
  background: #9980FA;
  color: white;
  padding: 0.3em 0.8em; }

.App__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2em;
  padding: 0.6em 1em 0; }
  .App__header__book {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 1em;
    right: 1em;
    background: white;
    padding: 0.4em 1em;
    width: 9em;
    font-size: 0.6em;
    border-radius: 0.5em;
    text-decoration: none;
    box-shadow: rgba(44, 62, 80, 0.23) 0px 0.4em 0.8em -2px, rgba(102, 119, 136, 0.2) 0px 1px 2px;
    transition: all 0.3s ease-out; }
    .App__header__book:hover {
      transform: translateY(-0.2em);
      box-shadow: rgba(44, 62, 80, 0.25) 0px 0.6em 1em -2px, rgba(102, 119, 136, 0.2) 0px 1px 2px; }
    .App__header__book__title {
      font-size: 0.7em;
      color: #9980FA;
      margin: 0;
      padding: 0;
      padding-left: 0.6em; }
    .App__header__book__image {
      width: 4em;
      margin-left: -2em;
      height: 100%;
      flex: 0 0 auto; }

.App__wrapper {
  display: flex; }

.App__content {
  flex: 1; }

.App__sidebar {
  flex: 0 0 480px;
  width: 480px;
  max-height: 100%;
  overflow: auto; }
  .App__sidebar__title {
    display: flex;
    justify-content: space-between;
    font-size: 1.5em;
    padding: 0 2rem;
    font-weight: 600;
    padding-top: 1em; }
    .App__sidebar__title__icon {
      opacity: 0.3;
      cursor: pointer;
      transition: opacity 0.2s ease-out; }
      .App__sidebar__title__icon:hover {
        opacity: 1; }

.button-no-appearance {
  appearance: none;
  box-shadow: none;
  border: none;
  text-align: left; }
