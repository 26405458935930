@import url(https://rsms.me/inter/inter.css);
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.transition-all {
  transition: all 0.3s ease-out;
}
.Code--theme-dark code[class*="language-"],
.Code--theme-dark pre[class*="language-"] {
  color: #f2f2f7;
  background: none;
  /* font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace; */ }

.Code--theme-dark .token.comment,
.Code--theme-dark .token.block-comment,
.Code--theme-dark .token.prolog,
.Code--theme-dark .token.doctype,
.Code--theme-dark .token.cdata {
  color: #9294a3; }

.Code--theme-dark .token.punctuation {
  color: #9294a3; }

.Code--theme-dark .token.selector,
.Code--theme-dark .token.tag,
.Code--theme-dark .token.namespace,
.Code--theme-dark .token.deleted {
  color: #75f7a6; }

.Code--theme-dark .token.function-name {
  color: #6196cc; }

.Code--theme-dark .token.attr-name,
.Code--theme-dark .token.boolean,
.Code--theme-dark .token.number,
.Code--theme-dark .token.function {
  color: #65ddd9; }

.Code--theme-dark .token.property,
.Code--theme-dark .token.class-name,
.Code--theme-dark .token.constant,
.Code--theme-dark .token.symbol {
  color: #ff767b; }

.Code--theme-dark .token.important,
.Code--theme-dark .token.atrule,
.Code--theme-dark .token.keyword,
.Code--theme-dark .token.builtin {
  color: #FFC312; }

.Code--theme-dark .token.string,
.Code--theme-dark .token.char,
.Code--theme-dark .token.attr-value,
.Code--theme-dark .token.regex,
.Code--theme-dark .token.variable {
  color: #b9a7ff; }

.Code--theme-dark .token.operator,
.Code--theme-dark .token.entity,
.Code--theme-dark .token.url {
  color: #ff8d58; }

.Code--theme-dark .token.important,
.Code--theme-dark .token.bold {
  font-weight: bold; }

.Code--theme-dark .token.italic {
  font-style: italic; }

.Code--theme-dark .token.entity {
  cursor: help; }

.Code--theme-dark .token.inserted {
  color: green; }

.Code--theme-dark pre[class*="language-"].line-numbers {
  position: relative;
  padding-left: 3.8em;
  counter-reset: linenumber; }

.Code--theme-dark pre[class*="language-"].line-numbers > code {
  position: relative;
  white-space: inherit; }

.Code--theme-dark .line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 0;
  font-size: 100%;
  left: -3.8em;
  width: 3em;
  /* works for line-numbers below 1000 lines */
  letter-spacing: -1px;
  border-right: 1px solid #8c9cad;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.Code--theme-dark .line-numbers-rows > span {
  pointer-events: none;
  display: block;
  counter-increment: linenumber; }

.Code--theme-dark .line-numbers-rows > span:before {
  content: counter(linenumber);
  color: #8c9cad;
  display: block;
  padding-right: 0.8em;
  text-align: right; }

.Code--theme-light code[class*="language-"],
.Code--theme-light pre[class*="language-"] {
  background: white;
  color: #2c3e50; }

.Code--theme-light .token.comment,
.Code--theme-light .token.block-comment,
.Code--theme-light .token.prolog,
.Code--theme-light .token.doctype,
.Code--theme-light .token.cdata {
  color: #8b8ba7; }

.Code--theme-light .token.punctuation {
  color: #8b8ba7; }

.Code--theme-light .token.selector,
.Code--theme-light .token.tag,
.Code--theme-light .token.namespace,
.Code--theme-light .token.deleted {
  color: #8068e0; }

.Code--theme-light .token.property,
.Code--theme-light .token.function-name {
  color: #467db4; }

.Code--theme-light .token.attr-name,
.Code--theme-light .token.boolean,
.Code--theme-light .token.number,
.Code--theme-light .token.function {
  color: #00beb8; }

.Code--theme-light .token.class-name,
.Code--theme-light .token.constant,
.Code--theme-light .token.symbol {
  color: #db4d52; }

.Code--theme-light .token.important,
.Code--theme-light .token.atrule,
.Code--theme-light .token.keyword,
.Code--theme-light .token.builtin {
  color: #d69d00; }

.Code--theme-light .token.string,
.Code--theme-light .token.char,
.Code--theme-light .token.attr-value,
.Code--theme-light .token.regex,
.Code--theme-light .token.variable {
  color: #8068e0; }

.Code--theme-light .token.operator,
.Code--theme-light .token.entity,
.Code--theme-light .token.url {
  color: #e6672d; }

.Code--theme-light .token.important,
.Code--theme-light .token.bold {
  font-weight: bold; }

.Code--theme-light .token.italic {
  font-style: italic; }

.Code--theme-light .token.entity {
  cursor: help; }

.Code--theme-light .token.inserted {
  color: green; }

.Code--theme-light pre[class*="language-"].line-numbers {
  position: relative;
  padding-left: 3.8em;
  counter-reset: linenumber; }

.Code--theme-light pre[class*="language-"].line-numbers > code {
  position: relative;
  white-space: inherit; }

.Code--theme-light .line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 0;
  font-size: 100%;
  left: -3.8em;
  width: 3em;
  /* works for line-numbers below 1000 lines */
  letter-spacing: -1px;
  border-right: 1px solid #8c9cad;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.Code--theme-light .line-numbers-rows > span {
  pointer-events: none;
  display: block;
  counter-increment: linenumber; }

.Code--theme-light .line-numbers-rows > span:before {
  content: counter(linenumber);
  color: #8c9cad;
  display: block;
  padding-right: 0.8em;
  text-align: right; }

.Icon {
  fill: currentColor;
  transform-origin: center center; }
  .Icon--direction-s {
    transform: rotate(90deg); }
  .Icon--direction-w {
    transform: rotate(180deg); }
  .Icon--direction-n {
    transform: rotate(-90deg); }
  .Icon--direction-ne {
    transform: rotate(-45deg); }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center; }

.p-2 {
  padding: 0.5em; }

.mb-2 {
  margin-bottom: 0.5em; }

.Button {
  display: inline-flex;
  align-items: center;
  padding: 0.6em 0.9em;
  background: #9980fa;
  color: white;
  font-size: 0.9em;
  line-height: 1em;
  outline: none;
  cursor: pointer;
  border-radius: 2px;
  transition: background 0.2s ease-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none; }
  .Button:focus, .Button:active, .Button:hover {
    background: #12cbc4;
    color: #f5f7f9; }
  .Button + .Button {
    margin-left: 0.3em; }
  .Button--selected {
    background: #114D4D;
    color: #f5f7f9; }
  .Button--style-secondary {
    background: #838399; }
    .Button--style-secondary:focus, .Button--style-secondary:active, .Button--style-secondary:hover {
      background: #292e31; }
  .Button:disabled {
    background: #bdbdcf; }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center; }

.p-2 {
  padding: 0.5em; }

.mb-2 {
  margin-bottom: 0.5em; }

.ClipboardTrigger {
  position: relative;
  padding: 0.2em;
  cursor: pointer; }
  .ClipboardTrigger__icon {
    opacity: 0.5;
    transition: opacity 0.2s ease-out; }
  .ClipboardTrigger:hover .ClipboardTrigger__icon {
    opacity: 1; }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center; }

.p-2 {
  padding: 0.5em; }

.mb-2 {
  margin-bottom: 0.5em; }

@keyframes confirmationFade {
  0% {
    opacity: 1;
    transform: translate3d(0.6em, 0, 0); }
  100% {
    opacity: 0;
    transform: translate3d(0.6em, -300%, 0); } }

.ConfirmationFade {
  padding: 0.5em 1em;
  text-align: center;
  font-size: 0.9em;
  line-height: 1.3em;
  color: #9980fa;
  background: rgba(244, 241, 254, 0.96);
  border-radius: 0.2em;
  font-weight: 600;
  transform: translate3d(0.6em, 0, 0);
  animation: confirmationFade 1s ease-out;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
  pointer-events: none;
  white-space: nowrap; }
  .ConfirmationFade__container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .ConfirmationFade__wrapper {
    position: fixed;
    top: -0.2em;
    margin-top: -0.3em;
    margin-right: -0.5em;
    z-index: 1000; }
    .ConfirmationFade__wrapper--placement-nw {
      transform: translate(-100%, -100%); }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center; }

.p-2 {
  padding: 0.5em; }

.mb-2 {
  margin-bottom: 0.5em; }

a,
.Link {
  color: #9980fa; }
  a:visited,
  .Link:visited {
    color: #e6672d; }
  a--type-button,
  .Link--type-button {
    background: #6E9987; }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center; }

.p-2 {
  padding: 0.5em; }

.mb-2 {
  margin-bottom: 0.5em; }

.Tooltip {
  position: relative; }
  .Tooltip__contents {
    position: fixed;
    padding: 0.6em 1em;
    background: white;
    font-size: 1em;
    text-align: center;
    border-radius: 6px;
    pointer-events: none;
    opacity: 0;
    transform: translate3d(-50%, -70%, 0);
    z-index: 1000;
    font-size: 0.8em;
    max-width: 30em;
    box-shadow: 0px 1px 7px 0px rgba(45, 53, 69, 0.25);
    border: 1px solid white;
    transition: opacity 0.3s ease-out, transform 0.3s ease-out; }
    .Tooltip__contents div {
      -webkit-font-smoothing: antialiased;
      -webkit-transform: translateZ(0) scale(1, 1); }
    .Tooltip__contents__note {
      font-size: 0.86em;
      line-height: 1.27em;
      margin: 0.2em 0;
      opacity: 0.6; }
    .Tooltip__contents__arrow {
      bottom: -8px;
      left: 50%;
      margin-left: -10px;
      margin-top: 0px;
      width: 21px;
      height: 20px;
      position: absolute;
      top: 100%;
      overflow: hidden;
      border-top: 0 solid white; }
      .Tooltip__contents__arrow::after {
        content: "";
        position: absolute;
        width: 9px;
        height: 9px;
        background: white;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        top: 0;
        left: 50%;
        box-shadow: 0px 1px 7px 0px rgba(45, 53, 69, 0.25); }
    .Tooltip__contents__close {
      position: absolute;
      top: 0.6em;
      right: 0.6em;
      padding: 0.3em;
      opacity: 0.5;
      cursor: pointer;
      z-index: 10;
      transition: opacity 0.3s ease-out; }
      .Tooltip__contents__close:hover {
        opacity: 1; }
    .Tooltip__contents--is-showing {
      transform: translate3d(-50%, -100%, 0);
      opacity: 1; }
    .Tooltip__contents--is-unmounting {
      opacity: 0; }
    .Tooltip__contents--top {
      margin-top: -10px; }
    .Tooltip__contents--bottom {
      transform: translate3d(-50%, 0, 0); }
      .Tooltip__contents--bottom .Tooltip__contents__arrow {
        bottom: auto;
        top: -20px;
        transform-origin: center center;
        transform: rotate(-180deg); }
    .Tooltip__contents--right {
      transform: translate3d(13px, -50%, 0); }
      .Tooltip__contents--right .Tooltip__contents__arrow {
        bottom: auto;
        top: 50%;
        left: -10px;
        margin-top: -10px;
        border-bottom-width: 8px;
        border-right-color: white;
        border-top-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
        transform: rotate(90deg); }
    .Tooltip__contents--left {
      transform: translate3d(0, -50%, 0);
      margin-left: -10px;
      margin-top: 0; }
      .Tooltip__contents--left .Tooltip__contents__arrow {
        bottom: auto;
        top: 50%;
        right: -20px;
        left: auto;
        margin-top: -10px;
        border-bottom-width: 10px;
        border-left-color: white;
        border-top-color: transparent;
        border-bottom-color: transparent;
        border-right-color: transparent;
        transform: rotate(-90deg); }
  .Tooltip .Link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center; }

.p-2 {
  padding: 0.5em; }

.mb-2 {
  margin-bottom: 0.5em; }

.Code {
  flex: 1 1;
  padding: 1em 0;
  overflow: auto; }
  .Code--theme-dark {
    background: #334150;
    color: white; }
    .Code--theme-dark .CodeLine--is-highlighted,
    .Code--theme-dark .CodeLine--is-highlighted > code[class*="language-"] {
      background: #24303d; }
    .Code--theme-dark .CodeLine:hover,
    .Code--theme-dark .CodeLine:hover > code[class*="language-"] {
      background: #24303d; }
    .Code--theme-dark .CodeLine__number, .Code--theme-dark .CodeLine__copy.ClipboardTrigger {
      color: white; }
  .Code--theme-light {
    background: #edeef7;
    color: #2c3e50; }
    .Code--theme-light .CodeLine--is-highlighted,
    .Code--theme-light .CodeLine--is-highlighted > code[class*="language-"] {
      background: white; }
    .Code--theme-light .CodeLine:hover,
    .Code--theme-light .CodeLine:hover > code[class*="language-"] {
      background: white; }
    .Code--theme-light__number, .Code--theme-light__copy.ClipboardTrigger {
      color: #2c3e50; }
  .Code__wrapper {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    margin-bottom: 1.3em;
    /*change the thickness of the scrollbar here*/
    /*add a shadow to the scrollbar here*/
    /*this is the little scrolly dealio in the bar*/
    /*nobody needs this little scrollbar corner, I mean really, get rid of it haha*/ }
    .Code__wrapper ::-webkit-scrollbar {
      -webkit-box-shadow: inset 0 0 6px black; }
    .Code__wrapper ::-webkit-scrollbar-track {
      -webkit-box-shadow: 0 0 0px white;
      background: #bdbdcf; }
    .Code__wrapper ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #9980fa; }
    .Code__wrapper ::-webkit-scrollbar-corner {
      display: none;
      height: 0px;
      width: 0px; }
  .Code__file {
    font-size: 0.7em;
    display: flex;
    align-items: center;
    background: #f2f2f7;
    color: #8b8ba7;
    padding: 0.3em 1em; }
    .Code__file__name {
      margin-left: 0.5em;
      font-weight: 500; }
    .Code__file__actions {
      display: flex;
      align-items: center;
      margin-left: auto; }
  .Code__copy {
    height: 23px; }
  .Code__eval {
    height: 19px;
    margin-right: 0.3em;
    padding: 0 0.3em;
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.2s ease-out; }
    .Code__eval:hover {
      opacity: 1; }
  .Code--wrap-none code[class*="language-"], .Code--wrap-none pre[class*="language-"] {
    white-space: pre !important;
    padding-right: 2em; }
  .Code--size-s {
    font-size: 0.7em;
    line-height: 1.2em; }
  .Code--highlighted-marker-0 .CodeLine__marker-0 {
    background: rgba(153, 128, 250, 0.27); }
  .Code--highlighted-marker-1 .CodeLine__marker-1 {
    background: rgba(18, 203, 196, 0.27); }
  .Code--highlighted-marker-2 .CodeLine__marker-2 {
    background: rgba(255, 195, 18, 0.27); }
  .Code--highlighted-marker-3 .CodeLine__marker-3 {
    background: rgba(230, 103, 45, 0.27); }
  .Code--highlighted-marker-4 .CodeLine__marker-4 {
    background: rgba(139, 139, 167, 0.27); }
  .Code--highlighted-marker-5 .CodeLine__marker-5 {
    background: rgba(251, 102, 184, 0.27); }
  .CodeLine {
    position: relative;
    padding: 0 2em;
    display: flex;
    align-items: baseline;
    cursor: text;
    min-height: 1.8em; }
    .CodeLine:hover .CodeLine__copy.ClipboardTrigger,
    .CodeLine:hover > code[class*="language-"] .CodeLine__copy.ClipboardTrigger {
      opacity: 1; }
    .CodeLine__marker {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border: 0 solid #9980fa;
      border-width: 0;
      border-left-width: 0.8em;
      pointer-events: none;
      transition: all 0.2s ease-out; }
      .CodeLine__marker-0 {
        border-color: #9980fa;
        background: rgba(153, 128, 250, 0); }
      .CodeLine__marker-1 {
        border-color: #12cbc4;
        background: rgba(18, 203, 196, 0); }
      .CodeLine__marker-2 {
        border-color: #ffc312;
        background: rgba(255, 195, 18, 0); }
      .CodeLine__marker-3 {
        border-color: #e6672d;
        background: rgba(230, 103, 45, 0); }
      .CodeLine__marker-4 {
        border-color: #8b8ba7;
        background: rgba(139, 139, 167, 0); }
      .CodeLine__marker-5 {
        border-color: #fb66b8;
        background: rgba(251, 102, 184, 0); }
    .CodeLine__number {
      padding: 0.23em 0;
      opacity: 0.5;
      font-size: 0.8em;
      flex: 0 0 3em;
      -webkit-user-select: none;
          -ms-user-select: none;
              user-select: none; }
    .CodeLine__copy.ClipboardTrigger {
      position: absolute;
      top: 50%;
      right: 1em;
      opacity: 0;
      transform: translateY(-50%);
      transition: opacity 0.2s ease-out; }
    .CodeLine code[class*="language-"] {
      padding: 0.2em 1em 0.2em 0;
      flex: 1 1; }
  .CodeStep {
    position: relative; }
    .CodeStep--is-collapsed {
      cursor: pointer;
      cursor: row-resize; }
      .CodeStep--is-collapsed .CodeLine--is-highlighted {
        background: none; }
      .CodeStep--is-collapsed .CodeStep__lines {
        max-height: 3.5em;
        margin-top: -1em;
        line-height: 0.6em;
        opacity: 0.3; }
        .CodeStep--is-collapsed .CodeStep__lines .CodeLine {
          pointer-events: none; }
        .CodeStep--is-collapsed .CodeStep__lines:before {
          opacity: 1; }
        .CodeStep--is-collapsed .CodeStep__lines .CodeLine__number {
          opacity: 0; }
        .CodeStep--is-collapsed .CodeStep__lines:hover {
          opacity: 1; }
    .CodeStep__copyable-text {
      -webkit-user-select: contain;
          -ms-user-select: element;
              user-select: contain;
      font-size: 0; }
    .CodeStep__top {
      padding: 0 2em;
      display: flex;
      align-items: baseline;
      -webkit-user-select: none;
          -ms-user-select: none;
              user-select: none;
      cursor: row-resize; }
    .CodeStep__name {
      display: flex;
      align-items: baseline;
      color: white;
      font-size: 1.2em;
      font-weight: 800; }
    .CodeStep__number {
      font-weight: 400;
      margin-right: 0.5em; }
    .CodeStep__close {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 0.77em;
      background: #9980fa;
      cursor: row-resize;
      z-index: 20;
      opacity: 0;
      transition: all 0.3s ease-out; }
    .CodeStep:hover .CodeStep__close {
      opacity: 1; }
    .CodeStep__lines {
      position: relative;
      max-height: 200em;
      transition: all 0.3s ease-out;
      overflow: hidden; }
      .CodeStep__lines:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(to bottom, rgba(51, 65, 80, 0), #334150);
        opacity: 0;
        transition: all 0.3s ease-out;
        pointer-events: none; }
  .Code__run-buttons {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 2em;
    right: 2em; }
  .Code__run-button {
    display: flex;
    align-items: center;
    font-size: 1.3em; }
    .Code__run-button .Icon {
      margin-left: 0.4em; }
  .Code code[class*="language-"],
  .Code pre[class*="language-"] {
    background: none;
    font-family: 'Operator Mono', 'Fira Code', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    /* font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace; */
    font-size: 1em;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    font-feature-settings: "zero" 0;
    -moz-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -ms-hyphens: none;
    hyphens: none; }
  .Code pre[class*="language-"] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto; }
  .Code :not(pre) > code[class*="language-"] {
    padding: .1em;
    border-radius: .3em;
    white-space: normal;
    background: none;
    border-radius: 0;
    white-space: pre-wrap;
    line-height: 1.5em; }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center; }

.p-2 {
  padding: 0.5em; }

.mb-2 {
  margin-bottom: 0.5em; }

.Interactions {
  max-width: 100vw;
  font-size: 16px;
  line-height: 1.3em; }
  .Interactions h1 {
    font-size: 3em;
    line-height: 1.1em;
    position: relative;
    z-index: 10; }
  .Interactions h2 {
    font-size: 2.3em;
    line-height: 1.1em;
    position: relative;
    z-index: 10; }
  @media (max-width: 800px) {
    .Interactions .Interactions__iframe {
      transform: scale(0.8); } }
  @media (max-width: 1100px) {
    .Interactions .ScrollEvent__indicator {
      display: none; }
    .Interactions .Interactions__trigger {
      display: none; } }
  .Interactions__inline-icon {
    color: #12cbc4;
    margin-bottom: -0.1em;
    margin-left: 0.06em; }
  .Interactions__trigger {
    position: -webkit-sticky;
    position: sticky;
    top: 48.3vh;
    margin-top: -18vh;
    margin-bottom: -9vh;
    height: 27vh;
    left: 0.5em;
    width: 1.6em;
    background: rgba(255, 195, 18, 0.3);
    margin-left: -2em;
    transform: translateY(-50%);
    z-index: 50; }
    .Interactions__trigger:before {
      content: "Code change zone";
      position: absolute;
      top: -0.3em;
      left: 50%;
      font-size: 0.6em;
      text-align: center;
      color: #cf9a00;
      font-weight: 500;
      line-height: 1.15em;
      transform: translate(-50%, -100%);
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s ease-out; }
    .Interactions__trigger:hover:before {
      opacity: 1; }
  .Interactions__trigger-example {
    padding: 0.1em 0.36em;
    background: rgba(255, 195, 18, 0.3); }
  .Interactions__mobile-code .Code {
    max-height: 20em;
    overflow: auto; }
  @media (max-width: 800px) {
    .Interactions .Code {
      font-size: 0.7em;
      line-height: 1.2em; } }
  .Interactions__content {
    position: relative;
    padding: 0 3rem 6em;
    width: calc(100% - 43rem - 1rem);
    font-size: 1.3em;
    line-height: 1.5em;
    transition: all 0.5s ease-out; }
    @media (max-width: 1300px) {
      .Interactions__content {
        width: calc(100% - 33rem - 1rem); } }
    @media (max-width: 1100px) {
      .Interactions__content {
        width: auto; } }
    @media (max-width: 800px) {
      .Interactions__content {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        width: auto; } }
    .Interactions__content .P {
      display: inline-block;
      margin: 0 0;
      padding: 0 0.6em;
      color: #9980fa;
      background: rgba(153, 128, 250, 0.1);
      border-radius: 2px;
      font-feature-settings: 'tnum' 1;
      font-family: monospace; }
    .Interactions__content img {
      max-width: 100%; }
  @media (min-width: 1300px) {
    .Interactions--start .Interactions__content {
      transform: translateX(10em); } }

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(2em); }
  100% {
    opacity: 1;
    transform: translateX(0); } }
  .Interactions__author {
    display: flex;
    align-items: center;
    position: fixed;
    top: 10em;
    right: 3rem;
    opacity: 0;
    font-size: 0.8em;
    animation: slideLeft 0.5s ease-out;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    padding: 0.8em 2em;
    max-width: 22em;
    background: linear-gradient(to top right, #efc4b7, #a0d8f8);
    background: #f2f2f7;
    background: rgba(153, 128, 250, 0.1);
    color: #8b8ba7;
    font-weight: 500;
    pointer-events: all; }
    @media (max-width: 1100px) {
      .Interactions__author {
        position: relative;
        top: auto;
        right: auto;
        width: auto; } }
    .Interactions__author .Interactions__author__book {
      width: 11em;
      flex: 0 0;
      margin-left: -1em;
      margin-right: -4.5em;
      max-width: none; }
    @media (max-width: 500px) {
      .Interactions__author {
        flex-direction: column;
        overflow: hidden; }
        .Interactions__author .Interactions__author__book {
          position: absolute;
          right: 1em;
          width: 9em; }
        .Interactions__author .Interactions__author__text {
          order: 3; } }
    .Interactions__author .Link {
      color: #7c84c4;
      white-space: nowrap; }
      .Interactions__author .Link:visited {
        color: #7c84c4; }
    .Interactions__author p {
      margin: 0.9em 0;
      line-height: 1.3em; }
  .Interactions__code {
    opacity: 0;
    animation: slideLeft 0.5s ease-out;
    animation-delay: 0.5s;
    animation-fill-mode: forwards; }
  .Interactions__iframe {
    height: 510px;
    margin: 0 -3rem;
    width: calc(100% + 6rem); }
    @media (max-width: 800px) {
      .Interactions__iframe {
        margin: 0 -5rem;
        width: calc(100% + 10rem); } }
  .Interactions__fixed-code {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 43rem;
    z-index: 2000;
    pointer-events: none; }
    @media (max-width: 1300px) {
      .Interactions__fixed-code {
        width: 33rem;
        font-size: 0.8em; } }
    @media (max-width: 1100px) {
      .Interactions__fixed-code {
        width: 0; } }
    .Interactions__fixed-code .Code__wrapper {
      min-height: 100%;
      margin-bottom: 0;
      pointer-events: all;
      overflow: auto; }
    .Interactions__fixed-code .Code {
      padding-bottom: 2em; }
      .Interactions__fixed-code .Code__file {
        font-size: 0.8em;
        padding: 0.6em 2.38em; }
  .Interactions__steps {
    margin-top: 1.5em;
    margin-bottom: 1.6em; }
  .Interactions__step {
    position: relative; }
    .Interactions__step__description {
      font-size: 0.9em;
      color: #8b8ba7;
      font-style: italic; }
  .Interactions .Wave {
    position: relative;
    width: 100%;
    height: 15em;
    margin-bottom: -6em;
    pointer-events: none; }
    .Interactions .Wave__path {
      transition: all 0.3s ease-out; }

.Heading {
  position: relative; }
  .Heading__hash {
    position: absolute;
    left: -0.9em;
    color: #bdbdcf;
    font-weight: 300;
    cursor: pointer;
    transition: color 0.2s ease-out; }
    .Heading__hash:hover {
      color: #9980fa; }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center; }

.p-2 {
  padding: 0.5em; }

.mb-2 {
  margin-bottom: 0.5em; }

.ScrollEvent {
  position: relative; }
  .ScrollEvent__listener {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none; }
  .ScrollEvent__indicator {
    display: flex;
    color: #12cbc4;
    border-radius: 100%; }
    .ScrollEvent__indicator.Tooltip {
      position: absolute;
      top: 0.1em;
      right: auto;
      bottom: auto;
      left: -1.6em; }
      .ScrollEvent__indicator.Tooltip .Tooltip__contents {
        white-space: nowrap; }
    .ScrollEvent__indicator .Icon {
      position: relative;
      z-index: 2; }
    .ScrollEvent__indicator--is-inactive {
      cursor: pointer; }
    .ScrollEvent__indicator--is-active {
      color: #9980fa; }

@keyframes popIn {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0; }
  90% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 1; }
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0; } }
      .ScrollEvent__indicator--is-active:before {
        content: "";
        position: absolute;
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        height: 1.5em;
        width: 1.5em;
        background: rgba(153, 128, 250, 0.3);
        border-radius: 100%;
        transform: translate(-50%, -50%) scale(0);
        opacity: 0;
        z-index: 0;
        animation: popIn 0.4s ease-out;
        animation-fill-mode: forwards; }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center; }

.p-2 {
  padding: 0.5em; }

.mb-2 {
  margin-bottom: 0.5em; }

.LocalExample {
  position: relative;
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  z-index: 2; }
  .LocalExampleLazy {
    z-index: 1; }
    .LocalExampleLazy__loading {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #8b8ba7;
      font-style: italic; }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center; }

.p-2 {
  padding: 0.5em; }

.mb-2 {
  margin-bottom: 0.5em; }

.List {
  padding: 0; }
  .List__item {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding-left: 0.5em;
    list-style: none; }
    .List__item .Code__wrapper {
      margin-top: 1em; }
    .List__item__icon {
      margin-top: 0.23em;
      color: #ffc312; }
    .List__item__number {
      color: #ffc312;
      margin-right: -0.5em; }
    .List__item__text {
      width: 100%;
      padding-bottom: 0.3em;
      padding-left: 1em; }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center; }

.p-2 {
  padding: 0.5em; }

.mb-2 {
  margin-bottom: 0.5em; }

.InteractionEvents {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 3em 0; }
  .InteractionEvents__circle {
    flex: none;
    height: 10em;
    width: 10em;
    background: #45aeb1;
    border-radius: 100%;
    margin: 1em 0; }
    .InteractionEvents__circle--index-1 {
      background: #9980fa; }
    .InteractionEvents__circle--index-2 {
      background: #12cbc4;
      margin-left: 1em;
      height: 6em;
      width: 6em; }
  .InteractionEvents__hint {
    position: absolute;
    bottom: 1em;
    color: #8b8ba7;
    font-style: italic;
    font-size: 0.6em;
    line-height: 1.2em; }
  .InteractionEvents__log {
    position: relative;
    display: flex;
    flex: 1 1;
    min-width: 9em;
    flex-direction: column;
    justify-content: flex-end;
    margin: 1em 0;
    margin-left: 2em;
    padding: 0 1em 0.7em;
    height: 100%;
    overflow: hidden;
    align-self: stretch;
    height: 10em;
    background: #f2f2f7;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .InteractionEvents__log:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 5em;
      background: linear-gradient(to top, rgba(242, 242, 247, 0), #f2f2f7 72%, #f2f2f7);
      z-index: 5; }
    .InteractionEvents__log h6 {
      position: absolute;
      top: 0;
      color: #8b8ba7;
      z-index: 10; }
    .InteractionEvents__log__item {
      font-size: 0.7em;
      line-height: 1.2em;
      white-space: normal;
      max-width: 100%;
      word-break: break-all; }
      .InteractionEvents__log__item--index-1 {
        color: #9980fa; }
      .InteractionEvents__log__item--index-2 {
        color: #12cbc4; }
      .InteractionEvents__log__item .Icon {
        margin-left: 0.1em; }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center; }

.p-2 {
  padding: 0.5em; }

.mb-2 {
  margin-bottom: 0.5em; }

.Aside {
  position: relative;
  margin: 1.7em 0;
  padding: 1.3em 1.5em;
  color: #34345b;
  font-size: 0.9em;
  line-height: 1.5em;
  border-left: 0.3em solid #9980fa;
  background: rgba(153, 128, 250, 0.09); }
  .Aside__icon {
    position: absolute;
    top: 0.8em;
    right: auto;
    bottom: auto;
    left: 0.3em;
    transform: translate(-80%, 0) rotate(-5deg);
    color: white;
    stroke: #bdbdcf;
    stroke-width: 1.3px;
    overflow: visible; }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center; }

.p-2 {
  padding: 0.5em; }

.mb-2 {
  margin-bottom: 0.5em; }

.Footer {
  display: flex;
  position: relative;
  align-items: flex-end;
  margin: 0 2rem 3em 2rem;
  background: rgba(153, 128, 250, 0.1);
  padding-right: 1em; }
  .Footer__image {
    flex: none;
    height: 10em;
    margin-top: -6em;
    margin-left: -2em; }
  .Footer__text {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding: 2em 1rem 1.6em 1rem; }
  .Footer__siblings {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0.3em -0.5em 0; }
  .Footer__links {
    display: flex;
    flex-wrap: wrap; }
  .Footer__link {
    margin: 0.5em;
    white-space: nowrap; }
    .Footer__link__emoji {
      margin-top: -2px;
      margin-right: 0.3em;
      width: 1em; }
    .Footer__link__icon {
      margin-right: 0.3em;
      margin-bottom: -3px; }
  @media (max-width: 750px) {
    .Footer {
      flex-direction: column;
      padding-bottom: 2em; }
      .Footer__links {
        margin-bottom: 1em; }
      .Footer__link {
        margin: 0.2em 0.5em;
        white-space: pre-wrap; }
      .Footer__image {
        position: absolute;
        bottom: 0;
        right: -1.5em;
        height: 8em;
        margin-left: 0; } }
  @media (max-width: 500px) {
    .Footer {
      padding-bottom: 1em; }
      .Footer__image {
        position: absolute;
        height: 6em;
        right: -1em; } }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center; }

.p-2 {
  padding: 0.5em; }

.mb-2 {
  margin-bottom: 0.5em; }

.NewsletterSignup {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 27em;
  max-width: 100%;
  padding: 0.3em;
  background: #fff;
  max-width: 74vw; }
  .NewsletterSignup__input {
    flex: 1 1;
    min-width: 10em;
    width: auto;
    padding: 0.4em 0.6em;
    font-weight: 400;
    border: 1px solid white;
    max-width: 100%; }
  .NewsletterSignup__submit {
    display: flex;
    align-items: center; }
    .NewsletterSignup__submit .Icon {
      margin-left: 0.3em; }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center; }

.p-2 {
  padding: 0.5em; }

.mb-2 {
  margin-bottom: 0.5em; }

.Loader {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slideUp 0.5s ease-out; }

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(4em); }
  100% {
    opacity: 1;
    transform: translateY(0); } }
  .Loader svg {
    overflow: visible; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  60% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(360deg); } }
  .Loader__triangle {
    transform-origin: 90px 90px;
    animation: spin 3s ease-out;
    animation-iteration-count: infinite; }
    .Loader__triangle:nth-child(1) {
      animation-delay: 0.5s;
      transform-origin: center center; }
    .Loader__triangle:nth-child(2) {
      animation-delay: 1s;
      transform-origin: 90px 140px; }
    .Loader__triangle:nth-child(3) {
      animation-delay: 1.5s;
      transform-origin: 140px 140px; }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center; }

.p-2 {
  padding: 0.5em; }

.mb-2 {
  margin-bottom: 0.5em; }

.Blog {
  padding: 4em 4em; }
  .Blog b {
    font-weight: 800; }
  .Blog em {
    font-style: italic;
    font-weight: 700; }
  .Blog__post {
    padding: 3em 0; }
    .Blog__post img {
      max-width: 30em; }
    .Blog__post__description {
      max-width: 45em; }
    .Blog__post p {
      margin: 1em 0;
      padding: 0; }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center; }

.p-2 {
  padding: 0.5em; }

.mb-2 {
  margin-bottom: 0.5em; }

.Header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 1em;
  z-index: 1000;
  pointer-events: none; }
  .Header__link {
    display: flex;
    align-items: center;
    color: #292e31;
    font-weight: 900;
    pointer-events: all; }
    .Header__link--blog {
      margin-left: auto;
      margin-right: 1em; }
    .Header__link:hover .Header__link__title {
      opacity: 1;
      transform: none; }
    .Header__link__title {
      position: relative;
      font-weight: 900;
      margin-left: 0.6em;
      z-index: 2;
      opacity: 0;
      transform: translateX(-1em);
      transition: all 0.2s ease-out; }
      .Header__link__title:before {
        content: "";
        position: absolute;
        top: -0.1em;
        bottom: -0.1em;
        left: -0.4em;
        right: -0.4em;
        background: white;
        z-index: -1; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, .btn, .pill, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: thin dotted; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

.flex {
  display: flex; }

.flex-col {
  flex-direction: column; }

.center {
  align-items: center;
  justify-content: center; }

.p-2 {
  padding: 0.5em; }

.mb-2 {
  margin-bottom: 0.5em; }

html {
  font-family: 'Inter', sans-serif; }

*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

body {
  background: #f5f7f9;
  color: #292e31;
  font-size: 18px;
  line-height: 1.8em;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.014em;
  font-feature-settings: "ss03", "zero", "cv08", "cv11" 1;
  color: #292e31;
  background: #f8f9fa;
  text-transform: none; }
  body ::selection {
    background: #114D4D;
    color: white; }

h1 {
  font-size: 6vw;
  line-height: 1.1em;
  line-height: 0.9em;
  margin: 0.2em 0 0.6em;
  font-weight: 900; }

h2 {
  font-size: 4em;
  line-height: 1.1em;
  margin: 1em 0 0;
  font-weight: 800;
  letter-spacing: -0.022em;
  font-weight: 900; }
  @media (max-width: 900px) {
    h2 {
      font-size: 3em; } }

h3 {
  font-size: 3em;
  line-height: 1.1em;
  margin-bottom: 0.6em;
  font-weight: 800;
  letter-spacing: -0.022em; }

h5 {
  font-size: 1.3em;
  line-height: 1.1em;
  margin-bottom: 0.9em;
  margin-top: 0.6em;
  font-weight: 800;
  letter-spacing: -0.022em; }

h6, .btn, .pill {
  font-size: 0.9em;
  line-height: 1.1em;
  margin: 0.5em 0;
  margin-bottom: 0.6em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 400;
  color: #bdbdcf; }
  h6 b, .btn b, .pill b {
    font-weight: 900; }

a {
  color: #6E9987;
  text-decoration: none;
  transition: all 0.2s ease-out; }
  a:hover {
    color: #114D4D; }
  a::selection {
    background: #fff; }

p {
  padding-bottom: 1em; }

.tnum {
  font-feature-settings: 'tnum' 1; }

.btn {
  color: #6E9987;
  padding: 0.9em 1.4em;
  background: #114D4D;
  border: 1px solid;
  letter-spacing: 0.06em;
  font-weight: 500;
  transition: all 0.2s ease-out;
  color: white;
  cursor: pointer; }
  .btn:visited {
    color: #fff;
    background: #6E9987; }
  .btnfocus, .btn:hover {
    -webkit-text-fill-color: #fff;
    color: #fff;
    background: #292e31;
    text-decoration: none; }

.pill {
  background: #114D4D;
  color: #fff;
  margin-bottom: 0.6em;
  padding: 0.6em 0.9em;
  display: inline-block;
  font-size: 12px;
  line-height: 1em;
  border-radius: 2px; }
  .pill + .pill {
    margin-left: 0.6em; }

input, textarea, select, button {
  font-family: 'Inter', sans-serif;
  font-feature-settings: "zero", "frac", "case", "ss01" 1; }

li {
  padding: 0.4em 0.6em; }

p {
  margin: 1.2em 0; }

b {
  font-weight: 800; }

:focus {
  outline: #9980FA auto 5px; }

@media (max-width: 1200px) {
  .desktop {
    display: none; } }

@media (min-width: 1200px) {
  .mobile {
    display: none; } }

.App ::selection {
  background: #9980FA;
  color: white;
  padding: 0.3em 0.8em; }

.App__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 2em;
  padding: 0.6em 1em 0; }
  .App__header__book {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 1em;
    right: 1em;
    background: white;
    padding: 0.4em 1em;
    width: 9em;
    font-size: 0.6em;
    border-radius: 0.5em;
    text-decoration: none;
    box-shadow: rgba(44, 62, 80, 0.23) 0px 0.4em 0.8em -2px, rgba(102, 119, 136, 0.2) 0px 1px 2px;
    transition: all 0.3s ease-out; }
    .App__header__book:hover {
      transform: translateY(-0.2em);
      box-shadow: rgba(44, 62, 80, 0.25) 0px 0.6em 1em -2px, rgba(102, 119, 136, 0.2) 0px 1px 2px; }
    .App__header__book__title {
      font-size: 0.7em;
      color: #9980FA;
      margin: 0;
      padding: 0;
      padding-left: 0.6em; }
    .App__header__book__image {
      width: 4em;
      margin-left: -2em;
      height: 100%;
      flex: 0 0 auto; }

.App__wrapper {
  display: flex; }

.App__content {
  flex: 1 1; }

.App__sidebar {
  flex: 0 0 480px;
  width: 480px;
  max-height: 100%;
  overflow: auto; }
  .App__sidebar__title {
    display: flex;
    justify-content: space-between;
    font-size: 1.5em;
    padding: 0 2rem;
    font-weight: 600;
    padding-top: 1em; }
    .App__sidebar__title__icon {
      opacity: 0.3;
      cursor: pointer;
      transition: opacity 0.2s ease-out; }
      .App__sidebar__title__icon:hover {
        opacity: 1; }

.button-no-appearance {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  box-shadow: none;
  border: none;
  text-align: left; }

