@import "src/styles/lib";

.ClipboardTrigger {
    position: relative;
    padding: 0.2em;
    cursor: pointer;

    &__icon {
        opacity: 0.5;
        transition: opacity 0.2s ease-out;
    }

    &:hover {
        .ClipboardTrigger__icon {
            opacity: 1;
        }
    }
}