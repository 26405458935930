$tc: #2c3e50;
$a1: #9980fa;
$a2: #12cbc4;
$a3: #ffc312;
$a4: #e6672d;
$a5: #fb66b8;

$grey1: #f2f2f7;
$grey2: #dbdbe7;
$grey3: #bdbdcf;
$grey4: #8b8ba7;
$grey5: #6a6a85;
$grey5: #34345b;

$text-color: #292e31;
