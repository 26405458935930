@import "src/styles/lib";

.Loader {
  @keyframes slideUp {
      0% { opacity: 0; transform: translateY(4em); }
    100% { opacity: 1; transform: translateY(0); }
  }

  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: slideUp 0.5s ease-out;

  svg {
    overflow: visible;
  }

  @keyframes spin {
      0% { transform: rotate(  0deg) }
     60% { transform: rotate(360deg) }
    100% { transform: rotate(360deg) }
  }

  &__triangle {
    transform-origin: 90px 90px;
    animation: spin 3s ease-out;
    animation-iteration-count: infinite;

    &:nth-child(1) { animation-delay: 0.5s; transform-origin: center center; }
    &:nth-child(2) { animation-delay: 1s; transform-origin: 90px 140px;  }
    &:nth-child(3) { animation-delay: 1.5s; transform-origin: 140px 140px;  }
  }
}
