$accent-1: #f5f7f9;
$accent-2: #6E9987;
$accent-3: #292e31;
$accent-4: #114D4D;

$gray: #e0e9ee;

$tc: $accent-3;
$bgc: $accent-1;

//

$font-family-serif: 'Playfair Display', serif;
$font-family-sans-serif: 'Inter', sans-serif;

//

$font-size-xlarge: 60px;
$font-size-large: 40px;
$font-size-medium: 24px;
$font-size-small: 18px;
$font-size-xsmall: 12px;

//

$transition-fast: 0.2s ease-out;

//

$horizontal-padding: 13em;
$horizontal-padding-skinny: 50px;

//

$z-index-tooltip: 1000;
