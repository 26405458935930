@import "src/styles/lib";

.Footer {
    display: flex;
    position: relative;
    align-items: flex-end;
    // width: 100%;
    margin: 0 2rem 3em 2rem;
    // font-size: 0.9em;
    background: rgba($a1, 0.1);
    padding-right: 1em;

    &__image {
        flex: none;
        height: 10em;
        margin-top: -6em;
        margin-left: -2em;
        // margin-bottom: -3em;
        // margin-left: -1em;
        // position: absolute;
        // right: 0;
        // bottom: 0;
        // width: 10em;
    }

    &__text {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 2em 1rem 1.6em 1rem;
    }

    &__siblings {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0.3em -0.5em 0;
    }

    &__links {
        display: flex;
        flex-wrap: wrap;
    }

    &__link {
        margin: 0.5em;
        white-space: nowrap;

        &__emoji {
            margin-top: -2px;
            margin-right: 0.3em;
            width: 1em;
        }

        &__icon {
            margin-right: 0.3em;
            margin-bottom: -3px;
        }
    }

    &__signup {
    }

    @media (max-width: 750px) {
        flex-direction: column;
        padding-bottom: 2em;

        &__links {
            margin-bottom: 1em;
        }

        &__link {
            margin: 0.2em 0.5em;
            white-space: pre-wrap;
        }

        &__image {
            position: absolute;
            bottom: 0;
            right: -1.5em;
            height: 8em;
            margin-left: 0;
        }
    }

    @media (max-width: 500px) {
        padding-bottom: 1em;

        &__image {
            position: absolute;
            height: 6em;
            right: -1em;
        }
    }
}