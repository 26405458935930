@import "src/styles/lib";

.Code {
  flex: 1;
  padding: 1em 0;
  overflow: auto;

  &--theme {
    &-dark {
      background: #334150;
      color: white;

      .CodeLine {
        &--is-highlighted,
        &--is-highlighted > code[class*="language-"] {
          background: #24303d;
        }

        &:hover,
        &:hover > code[class*="language-"] {
          background: #24303d;
        }

        &__number,
        &__copy.ClipboardTrigger {
          color: white;
        }
      }
    }
    &-light {
      background: #edeef7;
      color: #2c3e50;

      .CodeLine {
        &--is-highlighted,
        &--is-highlighted > code[class*="language-"] {
          background: white;
        }

        &:hover,
        &:hover > code[class*="language-"] {
          background: white;
        }
      }

      &__number,
      &__copy.ClipboardTrigger {
        color: #2c3e50;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    margin-bottom: 1.3em;

	/*change the thickness of the scrollbar here*/
  ::-webkit-scrollbar {
    -webkit-box-shadow: inset 0 0 6px black;
}
 /*add a shadow to the scrollbar here*/
::-webkit-scrollbar-track {
    -webkit-box-shadow: 0 0 0px white;
    background: $grey3;
}
/*this is the little scrolly dealio in the bar*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $a1;
}
/*nobody needs this little scrollbar corner, I mean really, get rid of it haha*/
  ::-webkit-scrollbar-corner       { display: none; height: 0px; width: 0px; }

  }

  &__file {
    font-size: 0.7em;
    display: flex;
    align-items: center;
    background: $grey1;
    color: $grey4;
    padding: 0.3em 1em;

    &__name {
      margin-left: 0.5em;
      font-weight: 500;
    }

    &__actions {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
  }

  &__copy {
    height: 23px;
  }

  &__eval {
    height: 19px;
    margin-right: 0.3em;
    padding: 0 0.3em;
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.2s ease-out;

    &:hover {
        opacity: 1;
    }
  }

  &--wrap-none {
    code[class*="language-"], pre[class*="language-"] {
      white-space: pre !important;
      padding-right: 2em;
    }
  }

  &--size {
    &-s {
      font-size: 0.7em;
      line-height: 1.2em;
    }
  }

  &--highlighted-marker {
    &-0 .CodeLine__marker-0 { background: rgba($a1, 0.27); }
    &-1 .CodeLine__marker-1 { background: rgba($a2, 0.27); }
    &-2 .CodeLine__marker-2 { background: rgba($a3, 0.27); }
    &-3 .CodeLine__marker-3 { background: rgba($a4, 0.27); }
    &-4 .CodeLine__marker-4 { background: rgba($grey4, 0.27); }
    &-5 .CodeLine__marker-5 { background: rgba($a5, 0.27); }
  }

  &Line {
    position: relative;
    // width: 100%;
    padding: 0 2em;
    display: flex;
    align-items: baseline;
    cursor: text;
    min-height: 1.8em;
    // transition: all 0.03s ease-out;

    &:hover,
    &:hover > code[class*="language-"] {
      .CodeLine__copy.ClipboardTrigger {
        opacity: 1;
      }
    }

    &__marker {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border: 0 solid $a1;
      border-width: 0;
      border-left-width: 0.8em;
      pointer-events: none;
      transition: all 0.2s ease-out;

        // &:before {
        //   content: "";
        //   width: 0.25em;
        //   // height: 0.5em;
        //   position: absolute;
        //   // top: 50%;
        //   top: 0.4em;
        //   bottom: 0.4em;
        //   left: 0.5em;
        //   z-index: 10;
        //   border-radius: 0.25em;
        //   transform: translate(-50%, 0);
        // }
        // margin-left: -3px;
      &-0 { border-color: $a1; background: rgba($a1, 0); }
      &-1 { border-color: $a2; background: rgba($a2, 0); }
      &-2 { border-color: $a3; background: rgba($a3, 0); }
      &-3 { border-color: $a4; background: rgba($a4, 0); }
      &-4 { border-color: $grey4; background: rgba($grey4, 0); }
      &-5 { border-color: $a5; background: rgba($a5, 0); }
      // &-0 { &:before {background: $a1 }}
      // &-1 { &:before {background: $a2 }}
      // &-2 { &:before {background: $a3 }}
      // &-3 { &:before {background: $a4 }}
    }

    &__number {
      padding: 0.23em 0;
      opacity: 0.5;
      // font-feature-settings: 'tnum' 1;
      font-size: 0.8em;
      flex: 0 0 3em;
      user-select: none;
    }

    &__copy.ClipboardTrigger {
      position: absolute;
      top: 50%;
      right: 1em;
      opacity: 0;
      transform: translateY(-50%);
      transition: opacity 0.2s ease-out;
    }

    code[class*="language-"] {
      padding: 0.2em 1em 0.2em 0;
      flex: 1;
    }
  }

  &Step {
    position: relative;

    &--is-collapsed {
      cursor: pointer;
      cursor: row-resize;

      .CodeLine--is-highlighted {
        background: none;
      }

      .CodeStep__lines {
        max-height: 3.5em;
        margin-top: -1em;
        line-height: 0.6em;
        opacity: 0.3;

        .CodeLine {
          pointer-events: none;
        }

        &:before {
          opacity: 1;
        }
        .CodeLine__number {
          opacity: 0;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    &__copyable-text {
      user-select: contain;
      font-size: 0;
    }

    &__top {
      padding: 0 2em;
      display: flex;
      align-items: baseline;
      user-select: none;
      cursor: row-resize;
    }

    &__name {
      display: flex;
      align-items: baseline;
      color: white;
      font-size: 1.2em;
      font-weight: 800;
    }

    &__number {
      font-weight: 400;
      margin-right: 0.5em;
    }

    &__close {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 0.77em;
      background: $a1;
      // cursor: pointer;
      cursor: row-resize;
      z-index: 20;
      opacity: 0;
      transition: all 0.3s ease-out;
    }

    &:hover {
      .CodeStep__close {
        opacity: 1;
      }
    }

    &__lines {
      position: relative;
      max-height: 200em;
      transition: all 0.3s ease-out;
      overflow: hidden;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(to bottom,
          rgba(#334150, 0),
          #334150
        );
        opacity: 0;
        transition: all 0.3s ease-out;
        pointer-events: none;
      }
    }
  }
  &__run-buttons {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 2em;
      right: 2em;
  }
  &__run-button {
      display: flex;
      align-items: center;
      font-size: 1.3em;

      .Icon {
          margin-left: 0.4em;
      }
  }


	code[class*="language-"],
	pre[class*="language-"] {
		background: none;
		font-family: 'Operator Mono', 'Fira Code', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
		/* font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace; */
		font-size: 1em;
		text-align: left;
		white-space: pre;
		word-spacing: normal;
		word-break: normal;
		word-wrap: normal;
    line-height: 1.5;
    font-feature-settings: "zero" 0;

		-moz-tab-size: 4;
		-o-tab-size: 4;
		tab-size: 4;

		-webkit-hyphens: none;
		-moz-hyphens: none;
		-ms-hyphens: none;
		hyphens: none;
	}

	pre[class*="language-"] {
		padding: 1em;
		margin: .5em 0;
		overflow: auto;
	}

	:not(pre) > code[class*="language-"] {
		padding: .1em;
		border-radius: .3em;
		white-space: normal;
    background: none;
    border-radius: 0;
    white-space: pre-wrap;
    line-height: 1.5em;
  }
}
