@import "src/styles/lib";

.LocalExample {
    position: relative;
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    z-index: 2;

    &Lazy {
        z-index: 1;

        &__loading {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $grey4;
            font-style: italic;
        }
    }
}