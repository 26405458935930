.Code--theme-light {
	code[class*="language-"],
	pre[class*="language-"] {
		background: white;
		color: #2c3e50;
	}


	.token.comment,
	.token.block-comment,
	.token.prolog,
	.token.doctype,
	.token.cdata {
		color: #8b8ba7;
	}

	.token.punctuation {
		color: #8b8ba7;
	}

	.token.selector,
	.token.tag,
	.token.namespace,
	.token.deleted {
		color: rgb(128, 104, 224);
	}

	.token.property,
	.token.function-name {
		color: #467db4;
	}

	.token.attr-name,
	.token.boolean,
	.token.number,
	.token.function {
		color: #00beb8;
	}

	.token.class-name,
	.token.constant,
	.token.symbol {
		color: #db4d52;
	}

	.token.important,
	.token.atrule,
	.token.keyword,
	.token.builtin {
		color: #d69d00;
	}

	.token.string,
	.token.char,
	.token.attr-value,
	.token.regex,
	.token.variable {
		color: rgb(128, 104, 224);
	}

	.token.operator,
	.token.entity,
	.token.url {
		color: #e6672d;
	}

	.token.important,
	.token.bold {
		font-weight: bold;
	}
	.token.italic {
		font-style: italic;
	}

	.token.entity {
		cursor: help;
	}

	.token.inserted {
		color: green;
	}

	pre[class*="language-"].line-numbers {
		position: relative;
		padding-left: 3.8em;
		counter-reset: linenumber;
	}

	pre[class*="language-"].line-numbers > code {
		position: relative;
		white-space: inherit;
	}

	.line-numbers .line-numbers-rows {
		position: absolute;
		pointer-events: none;
		top: 0;
		font-size: 100%;
		left: -3.8em;
		width: 3em; /* works for line-numbers below 1000 lines */
		letter-spacing: -1px;
		border-right: 1px solid #8c9cad;

		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

	}

	.line-numbers-rows > span {
		pointer-events: none;
		display: block;
		counter-increment: linenumber;
	}

	.line-numbers-rows > span:before {
		content: counter(linenumber);
		color: #8c9cad;
		display: block;
		padding-right: 0.8em;
		text-align: right;
	}
	// pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
	// code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
	// 	text-shadow: none;
	// 	background: #dfe2f1;
	// }

	// pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
	// code[class*="language-"]::selection, code[class*="language-"] ::selection {
	// 	text-shadow: none;
	// 	background: #dfe2f1;
	// }

	// .token.comment,
	// .token.prolog,
	// .token.doctype,
	// .token.cdata {
	// 	color: #898ea4;
	// }

	// .token.punctuation {
	// 	color: #5e6687;
	// }

	// .token.namespace {
	// 	opacity: .7;
	// }

	// .token.operator,
	// .token.boolean,
	// .token.number {
	// 	color: #c76b29;
	// }

	// .token.property {
	// 	color: #c08b30;
	// }

	// .token.tag {
	// 	color: #3d8fd1;
	// }

	// .token.string {
	// 	color: #22a2c9;
	// }

	// .token.selector {
	// 	color: #6679cc;
	// }

	// .token.attr-name {
	// 	color: #c76b29;
	// }

	// .token.entity,
	// .token.url,
	// .language-css .token.string,
	// .style .token.string {
	// 	color: #22a2c9;
	// }

	// .token.attr-value,
	// .token.keyword,
	// .token.control,
	// .token.directive,
	// .token.unit {
	// 	color: #ac9739;
	// }

	// .token.statement,
	// .token.regex,
	// .token.atrule {
	// 	color: #22a2c9;
	// }

	// .token.placeholder,
	// .token.variable {
	// 	color: #3d8fd1;
	// }

	// .token.deleted {
	// 	text-decoration: line-through;
	// }

	// .token.inserted {
	// 	border-bottom: 1px dotted #202746;
	// 	text-decoration: none;
	// }

	// .token.italic {
	// 	font-style: italic;
	// }

	// .token.important,
	// .token.bold {
	// 	font-weight: bold;
	// }

	// .token.important {
	// 	color: #c94922;
	// }

	// .token.entity {
	// 	cursor: help;
	// }

	// pre > code.highlight {
	// 	outline: 0.4em solid #c94922;
	// 	outline-offset: .4em;
	// }

	// /* overrides color-values for the Line Numbers plugin
	//  * http://prismjs.com/plugins/line-numbers/
	//  */
	// .line-numbers .line-numbers-rows {
	// 	border-right-color: #dfe2f1;
	// }

	// .line-numbers-rows > span:before {
	// 	color: #979db4;
	// }

	// /* overrides color-values for the Line Highlight plugin
	//  * http://prismjs.com/plugins/line-highlight/
	//  */
	// .line-highlight {
	// 	background: rgba(107, 115, 148, 0.2);
	// 	background: -webkit-linear-gradient(left, rgba(107, 115, 148, 0.2) 70%, rgba(107, 115, 148, 0));
	// 	background: linear-gradient(to right, rgba(107, 115, 148, 0.2) 70%, rgba(107, 115, 148, 0));
	// }
}

