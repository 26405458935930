@import "src/styles/reset";
@import "src/styles/lib";


@import url('https://rsms.me/inter/inter.css');
html { font-family: 'Inter', sans-serif; }
// @supports (font-variation-settings: normal) {
//   html { font-family: 'Inter var', sans-serif; }
// }

*, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: $bgc;
    color: $tc;
    font-size: 18px;
    line-height: 1.8em;
    font-family: $font-family-sans-serif;
    letter-spacing: -0.014em;
    font-feature-settings: "ss03", "zero", "cv08", "cv11" 1;
    color: $tc;
    background: #f8f9fa;
    text-transform: none;

    ::selection {
        background: $accent-4;
        color: white;
    }
}


h1 {
    font-size: 6vw;
    line-height: 1.1em;
    // font-size: 8vw;
    line-height: 0.9em;
    margin: 0.2em 0 0.6em;
    font-weight: 900;
}

h2 {
    // font-family: $font-family-serif;
    font-size: 4em;
    line-height: 1.1em;
    margin: 1em 0 0;
    // margin-bottom: 0.6em;
    font-weight: 800;
    letter-spacing: -0.022em;
        // padding: 1em 0 0.7em;
        // font-size: 1.7em;
        font-weight: 900;

    @media (max-width: 900px) {
        font-size: 3em;
    }
}

h3 {
  // font-family: $font-family-serif;
  font-size: 3em;
  line-height: 1.1em;
  margin-bottom: 0.6em;
  font-weight: 800;
  letter-spacing: -0.022em;
}

h5 {
  // font-family: $font-family-serif;
  font-size: 1.3em;
  line-height: 1.1em;
  margin-bottom: 0.9em;
  margin-top: 0.6em;
  font-weight: 800;
  letter-spacing: -0.022em;
}

h6 {
  font-size: 0.9em;
  line-height: 1.1em;
  margin: 0.5em 0;
  margin-bottom: 0.6em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 400;
  color: $grey3;

    b {
        font-weight: 900;
    }
}

a {
color: $accent-2;
text-decoration: none;
transition: all 0.2s ease-out;
&:hover {
    color: $accent-4;
}
&::selection {
    background: #fff;
}
}

p {
padding-bottom: 1em;
}

.tnum {
  font-feature-settings: 'tnum' 1;
}

.btn {
    @extend h6;
    color: $accent-2;
    padding: 0.9em 1.4em;
    background: $accent-4;
    border: 1px solid;
    letter-spacing: 0.06em;
    font-weight: 500;
    transition: all 0.2s ease-out;
    color: white;
    cursor: pointer;

    &:visited {
        color: #fff;
        background: $accent-2;
    }

    &focus,
    &:hover {
    -webkit-text-fill-color: #fff;
        color: #fff;
        background: $accent-3;
        // border-color: $accent-2;
        text-decoration: none;
    }
}

.pill {
@extend h6;
background: $accent-4;
color: #fff;
margin-bottom: 0.6em;
padding: 0.6em 0.9em;
display: inline-block;
font-size: $font-size-xsmall;
line-height: 1em;
border-radius: 2px;
& + .pill {
    margin-left: 0.6em;
}
}


input, textarea, select, button {
  font-family: 'Inter', sans-serif;
  font-feature-settings: "zero", "frac", "case", "ss01" 1;
}

li {
    padding: 0.4em 0.6em;
}


p {
    margin: 1.2em 0;
}

b {
    font-weight: 800;
}

:focus {
    outline: #9980FA auto 5px;
}

.desktop {
    @media (max-width: 1200px) {
        display: none;
    }
}

.mobile {
    @media (min-width: 1200px) {
        display: none;
    }
}

.App {
//   background: #f4f4f4;

  ::selection {
    background: #9980FA;
    color: white;
    padding: 0.3em 0.8em;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2em;
    padding: 0.6em 1em 0;

    &__book {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      bottom: 1em;
      right: 1em;
      background: white;
      padding: 0.4em 1em;
      width: 9em;
      font-size: 0.6em;
      border-radius: 0.5em;
      text-decoration: none;
      box-shadow: rgba(44, 62, 80, 0.23) 0px 0.4em 0.8em -2px, rgba(102, 119, 136, 0.2) 0px 1px 2px;
      transition: all 0.3s ease-out;

      &:hover {
        transform: translateY(-0.2em);
        box-shadow: rgba(44, 62, 80, 0.25) 0px 0.6em 1em -2px, rgba(102, 119, 136, 0.2) 0px 1px 2px;
      }

      &__title {
        font-size: 0.7em;
        color: #9980FA;
        margin: 0;
        padding: 0;
        padding-left: 0.6em;
      }

      &__image {
        width: 4em;
        margin-left: -2em;
        height: 100%;
        flex: 0 0 auto;
      }
    }
  }

  &__wrapper {
    display: flex;
  }

  &__content {
    flex: 1;
  }

  &__sidebar {
    flex: 0 0 480px;
    width: 480px;
    max-height: 100%;
    overflow: auto;

    &__title {
      display: flex;
      justify-content: space-between;
      font-size: 1.5em;
      padding: 0 2rem;
      font-weight: 600;
      padding-top: 1em;

      &__icon {
        opacity: 0.3;
        cursor: pointer;
        transition: opacity 0.2s ease-out;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.button-no-appearance {
  appearance: none;
  box-shadow: none;
  border: none;
  text-align: left;
}

